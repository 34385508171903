/**
 * Country Selector Landing page
 * This JS module will handle the necessary user interactions with the
 * country selector landing page
 *
 * @author Jamie Kimmell <jkimmell@fluid.com>
 */

var breakpoints = require('./components/breakpoints');
var debounce = require('lodash/debounce');

/**
 * Set Cookie
 * Save a cookie
 *
 * @param {string} name Name of the Cookie to set
 * @param {string} value Value to set the cookie to
 * @param {int} days Number of Days to set the cookie for
 */
function setCookie(name, value, days) {
    var expires = '';
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

/**
 * Calculate the tallest region name element, and set the height of all
 * region names to be the same
 * Desktop only
 *
 * @param {*} $landingPageMainElement The JQuery reference to the landing page main element
 */
function fixRegionNameHeight($landingPageMainElement) {
    var $regionNames = $landingPageMainElement.find('.regions .region-name');
    if (breakpoints.isCurrentBreakpoint('md', 'lg', 'xl')) {
        var tallestName = 0;

        $regionNames.each(function () {
            var regionName = $(this);
            var height = parseInt(regionName.height(), 10);

            if (height > tallestName) {
                tallestName = height;
            }
        });

        $regionNames.height(tallestName);
    } else {
        $regionNames.height('auto');
    }
}

/**
 * Create Events
 * Create the necessary JS events for the Country Selector Language Page
 * @param {*} landingPageMainElement The JQuery reference to the landing page main element
 */
function createEvents(landingPageMainElement) {
    var regionCountrySections = landingPageMainElement.find('.desktop-region-countries');
    var countryLinks = landingPageMainElement.find('.js-action__gotocountry');
    var regions = landingPageMainElement.find('.js-action__showregion');

    /**
     * Clicking on a region should select / un-select that region
     * and show / hide the relevant country list
     */
    regions.on('click', function (event) {
        event.preventDefault();
        event.stopImmediatePropagation();

        regionCountrySections.hide();

        var region = $(this);
        var toShow = region.attr('data-target');

        /**
         * Add the selection-made class to the parent element
         */
        region.parents('.regions').addClass('selection-made');

        /**
         * If the region's parent has the "selected" class,
         * we should un-select that region and hide the country list
         * Otherwise, we should do the reverse
         */
        if (region.parent().hasClass('selected')) {
            landingPageMainElement.find(toShow).hide();
            region.parent().removeClass('selected');
            region.find('svg.accordion-icon').children('use').attr('href', '#add');
        } else {
            landingPageMainElement.find(toShow).show();
            regions.parent().removeClass('selected');
            region.parent().addClass('selected');
            region.find('svg.accordion-icon').children('use').attr('href', '#remove');
        }
    });

    /**
     * Click on a country link should save a cookie for the selected country
     * so the user won't see the country verification overlay
     */
    countryLinks.on('click', function () {
        setCookie('countrySelectionCookie', $(this).attr('data-country-code'), 30);
    });

    /**
     * When the Window resizes, reset the User Experience to it's default state
     */
    function resetAccordions() {
        if (breakpoints.isCurrentBreakpoint('xs', 'sm')) {
            $('.desktop-region-countries').hide();
        } else {
            $('.mobile-region-countries').hide();
            regions.find('svg.accordion-icon').children('use').attr('href', '#add');
            regions.parent().removeClass('selected');
        }
        fixRegionNameHeight(landingPageMainElement);
    }

    var debounceResetAccordions = debounce(resetAccordions, 300);
    window.addEventListener('resize', debounceResetAccordions);
}

/**
 * Initialize the country selector landing page
 * This should create the necessary events and make the height
 * of all region names uniform
 */
function initializeLandingPage() {
    var landingPageMainElement = $('.country-selector-landing-page');

    fixRegionNameHeight(landingPageMainElement);
    createEvents(landingPageMainElement);
}

$(document).ready(function () {
    initializeLandingPage();
});
